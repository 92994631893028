import useCmsPage from '@hooks/useCmsPage';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';

interface CmsHeadProps {
  cmsPageId: string;
}

const CmsHead = ({ cmsPageId }: CmsHeadProps) => {
  const { cmsPage } = useCmsPage(cmsPageId);
  const { t } = useTranslation('common');

  const { title, description } = cmsPage || {};
  const titleWithSuffix = !!title && `${title}${t('seo->defaultTitleSuffix')}`;

  if (!titleWithSuffix && !description) {
    return null;
  }
  return (
    <>
      <Head>
        {!!titleWithSuffix && <title>{titleWithSuffix}</title>}
        {!!description && <meta name="description" key="description" content={description} />}
      </Head>
    </>
  );
};

export default CmsHead;
